import React from 'react'
import './Plan.css'
import { Map, TileLayer, Marker } from 'react-leaflet'

export default class Plan extends React.Component {
  render() {
    const options = this.props
    const position = options.pos
    const tilelayerurl = 'https://{s}.tile.osm.org/{z}/{x}/{y}.png'
    const zoom = 14

    return (
      <div className="mapWrapper">
        <Map center={position} zoom={zoom}>
          <TileLayer
            url={tilelayerurl}
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position} />
        </Map>
      </div>
    )
  }
}
