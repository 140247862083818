import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BlogPostTemplate from '../components/BlogPostTemplate/BlogPostTemplate'
import LinkCloud from '../components/LinkCloud/LinkCloud'
import SocialSharing from '../components/SocialSharing/SocialSharing'

const BlogPost = ({ data }) => {
  const { wpPost: post, site } = data
  const pageTitle = post.seo.metaTitle ? post.seo.metaTitle : post.title
  const { title: siteTitle, siteUrl } = site.siteMetadata
  const { bigSquareAside } = site.siteMetadata.settings
  const shareUrl = `${siteUrl}/${post.slug}`

  return (
    <Layout>
      <Helmet>
        <title>{`${pageTitle} | ${siteTitle}`}</title>
        <link rel="canonical" href={`${shareUrl}/`} />
        <meta name="description" content={post.seo.metaDescription} />
        <meta name="og:title" content={`${pageTitle} | Pretparkvoordeel.be`} />
        <meta name="og:description" content={post.seo.ogDescription} />
        <meta name="og:image" content={post.seo.ogImage} />
      </Helmet>

      <BlogPostTemplate {...post} bigSquareAside={bigSquareAside} />
      <SocialSharing shareUrl={shareUrl} />
      <LinkCloud />
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on WpPost {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          name
          slug
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100, placeholder: BLURRED)
            }
          }
        }
      }
      seo {
        metaDescription: metaDescription
        metaTitle: metaTitle
        ogDesc: ogDescription
        ogImg: ogImage
      }
      banners {
        dagticketsLink: dagticketsLink
        bigSquareSrc: bigSquareSrc
        leaderboardSrc: leaderboardSrc
      }
      meer {
        bijzonderheden
        map {
          latitude
          longitude
        }
        pretparkLogo: pretparkLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, width: 250)
            }
          }
        }
      }
      weatherData {
        weatherId
        weatherPlaats
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
        settings {
          bigSquareAside
        }
      }
    }
  }
`
