import React from 'react'

export default class Weather extends React.Component {
  render() {
    const { weatherPlaats, weatherId } = this.props

    return (
      <div className="weather-widget">
        <div className="ww-title has-text-centered">Weersverwachting</div>

        <div>
          <iframe
            title="widget"
            width="100%"
            height="328px"
            frameBorder="0"
            scrolling="no"
            src={`https://www.meteovista.be/widget/weather?id=${weatherId}`}
          ></iframe>
        </div>

        <div className="ww-title has-text-centered">Meteovista.be</div>
      </div>
    )
  }
}
