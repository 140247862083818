import React from 'react'

export default class LeaderBord extends React.Component {
  render() {
    const { src } = this.props
    return (
      <div className="leaderBoard img-marg">
        {src ? (
          <div
            dangerouslySetInnerHTML={{
              __html: src,
            }}
          />
        ) : null}
      </div>
    )
  }
}
