import React from 'react'

export default class BigSquare extends React.Component {
  render() {
    const src = this.props.src

    return (
      <div className="bigSquare img-marg is-flex is-horizontal-center">
        {src ? (
          <div
            dangerouslySetInnerHTML={{
              __html: src,
            }}
          />
        ) : null}
      </div>
    )
  }
}
