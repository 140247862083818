import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

export default class PretparkLogo extends React.Component {
  render() {
    const pretParklogo = this.props.src
    return (
      <div className="pretpark-logo is-clearfix">
        <GatsbyImage
          image={pretParklogo.localFile?.childImageSharp?.gatsbyImageData}
        />
      </div>
    )
  }
}
