import React from 'react'

export default class DagTickets extends React.Component {
  render() {
    const dagtickets = this.props.dagtickets
    const title = this.props.title

    return (
      <div className="div-marg">
        <a href={dagtickets} className="is-block has-text-centered">
          {title} voordelig via Dagtickets
        </a>
        <br />
        <a href={dagtickets}>
          <img src="https://static-dscn.net/7117/1373363/?wi=332067&ws=" />
        </a>
      </div>
    )
  }
}
